import React from "react";

function Organizer() {
    const marquess = ['Organizer']; // Replace with your actual array
    const AllMarquess = Array(10).fill(marquess).flat();
    return (
      <section className="intro section-padding-top sub-bg bord-bottom-grd">
        <div className="ml-50 mr-50 mb-50">
          <div className="row lg-marg">
            <div className="col-lg-12">
              <div className="row lg-marg">
                <div className="col-md-6 mb-80">
                  <div>
                    <h4 className="main-color mb-30">EVENT ADVISERS</h4>
                    <div className="row lg-marg mt-50">
                      <div className="text">
                        <p className="mb-10 fw-600">Event Adviser{' : '}<span className="fw-100">Dr. Drs. AG. Sudibyo, M.Si</span></p>
                        <p className="mb-10 fw-600">Event Facilitator{' : '}<span className="fw-100">Prof. Dr. Dody Prayogo, MPSt</span></p>
                        <p className="mb-10 fw-600">Organizer{' : '}<span className="fw-100">MAPALA UI</span></p>
                      </div>
                    </div>
                    <div className="row lg-marg mt-50">
                      <div className="text">
                        <p className="mb-10 fw-600">Event Controller{' : '}<span className="fw-100">Andrawinaning Tyas Raras</span></p>
                        <p className="mb-10 fw-600">Timing System{' : '}<span className="fw-100">Wahyudi Setiaji</span></p>
                        <p className="mb-10 fw-600">Cartography{' : '}<span className="fw-100">Muhammad Afif</span></p>
                        <p className="mb-10 fw-600">IOF Event Adviser{' : '}<span className="fw-100">Syamsudin Zubair</span></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <h4 className="main-color mb-30">ORGANIZING COMMITTEE</h4>
                  </div>
                  <div className="row lg-marg mt-50">
                      <div className="text">
                        <p className="mb-10 fw-600">Chief of Mapala UI{' : '}<span className="fw-100">Muhammad Fikra Alfath F.</span></p>
                        <p className="mb-10 fw-600">Event Director{' : '}<span className="fw-100">Siti Fadilah Apriani</span></p>
                        <p className="mb-10 fw-600">Vice Event Director{' : '}<span className="fw-100">Aldes Alfarizi</span></p>
                        <p className="mb-10 fw-600">Event Secretary{' : '}<span className="fw-100">Diama Gabriela T.</span></p>
                        <p className="mb-10 fw-600">Finance{' : '}<span className="fw-100">Devin Xavier G.</span></p>
                        <p className="mb-10 fw-600">Race Director{' : '}<span className="fw-100">Salman Muzhaffar H.</span></p>
                        <p className="mb-10 fw-600">Event Coordinator{' : '}<span className="fw-100">Surya Anderson S.</span></p>
                        <p className="mb-10 fw-600">PR Coordinator{' : '}<span className="fw-100">Ulya Rachma A.</span></p>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="marq-head mb-50">
          <div className="main-marq xlrg text-u o-hidden">
            <div className="slide-har st1">
              <div className="box">
                {AllMarquess.map((item, i) => (
                  <div key={i} className="item">
                    <h4>{item}</h4>
                  </div>
                ))}
              </div>
              <div className="box">
                {AllMarquess.map((item, i) => (
                  <div key={i} className="item">
                    <h4>{item}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
    </section>
    );
}

export default Organizer;
